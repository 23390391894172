import React from 'react'
import Layout from "../components/layout"


const AboutPage = () => (
    <Layout>
        <h1>ABOUT</h1>
        <p>Velit nulla dolore exercitation non est proident duis pariatur elit fugiat. Esse exercitation reprehenderit elit labore dolore non reprehenderit. Non pariatur anim ipsum id consectetur tempor dolor qui ea est non amet. Id commodo adipisicing aute deserunt sunt dolore. Adipisicing velit quis ullamco tempor non consequat Lorem do labore Lorem magna. Tempor exercitation ad id minim commodo et eu. </p>

        <p>Velit nulla dolore exercitation non est proident duis pariatur elit fugiat. Esse exercitation reprehenderit elit labore dolore non reprehenderit. Non pariatur anim ipsum id consectetur tempor dolor qui ea est non amet. Id commodo adipisicing aute deserunt sunt dolore. Adipisicing velit quis ullamco tempor non consequat Lorem do labore Lorem magna. Tempor exercitation ad id minim commodo et eu. </p>
    </Layout>
)

export default AboutPage;
